
import React, { useState, useEffect } from 'react'
import { TextField, Button, Tooltip, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import { useSnackbar } from 'notistack';
import { GoogleLocationField } from '../../../shared_elements';
import InfoIcon from '@material-ui/icons/Info';

export default function EngineFilter({ setFilter, filter, processingLoader, getResponseBack }) {
    const [engineTypeList, setEngineTypeList] = useState([])
    const [derateList, setDerateList] = useState([])
    const [regionCondition, setRegionCondition] = useState({});
    const [utilizationList, setutilizationList] = useState([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => {
        getEngineTypeApi()
        let ratioList =[]
        for (let ratio = 0.5; ratio <= 5 ; ratio = ratio + 0.1) {
            ratioList.push({ value: ratio.toFixed(1), label: ratio.toFixed(1) + ':1' });
        }
        setutilizationList(ratioList);
    }, [])
    const changeGenericFilter = (key, value) => {
        if (value?.length <= 5 || key !== 'engine_type') {
            setFilter(prevState => ({
                ...prevState,
                [key]: value
            }))
            if (value?.length && key === 'engine_type') {
                let minRatio = value.map((item)=> engineTypeList.find(listItem=> listItem.id === item?.id)?.utilization_min_ratio)
                let maxRatio = value.map((item)=> engineTypeList.find(listItem=> listItem.id === item?.id)?.utilization_max_ratio)
                let ratioList = [];
                for (let ratio = Math.min(...minRatio); ratio <= Math.max(...maxRatio) ; ratio = ratio + 0.1) {
                    ratioList.push({ value: ratio.toFixed(1), label: ratio.toFixed(1) + ':1' });
                }
                setutilizationList(ratioList);
                let ratioListLabels = ratioList.map(item => {return( item?.label)})
                if(ratioListLabels?.includes(filter?.utlizationRatio?.label) ){
                    return
                }
                else{
                    setFilter(prevState => ({...prevState,utlizationRatio: null}))
                }
            }
            else if (key === 'engine_type' && value?.length < 1) {
                setutilizationList([]);
                setFilter(prevState => ({
                    ...prevState,
                    utlizationRatio: null
                }))
            }
        }
        else {
            enqueueSnackbar('Max 5 values can be selected.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
    }
    const getAddressLocationDetails = (address) => {
        if (address) {
            let regionObj = {};
            address.address_components.map(location => {
                let types = location.types[0];
                switch (types) {
                    case 'country':
                        regionObj = {
                            ...regionObj,
                            country_name: location.long_name,
                            country_iso_2: location.short_name
                        }
                        return
                    case "administrative_area_level_1":
                        regionObj = {
                            ...regionObj,
                            state_name: location.long_name,
                            state_code: location.short_name
                        }
                        return
                    case "administrative_area_level_2":
                        regionObj = {
                            ...regionObj,
                            city_name: location.long_name,
                            city_code: location.short_name
                        }
                        return
                    default:
                        return
                }
            });
            regionObj = {
                ...regionObj,
                address: `${regionObj.city_name ? regionObj.city_name : ''}${regionObj.city_name ? ', ' : ''}${regionObj.state_name ? regionObj.state_name : ''}${regionObj.state_name ? ', ' : ''}${regionObj.country_name}`
            }
            getRegionVariables({ engine_type: filter?.engine_type[0]?.id, location: regionObj })
            return regionObj;
        }
    }
    const getRegionVariables = (data) => {
        globalPostService(`console/regions_maps/`, data)
            .then(response => {
                if (checkApiStatus(response)) {
                    setFilter(prevState => ({
                        ...prevState,
                        region_of_operation: response.data.data?.current_region
                    }))
                    setDerateList(response.data.data?.variable_condition[1]?.ranges)
                }
            })
    }

    const getEngineTypeApi = () => {
        globalGetService('console/engine-types/', { 'mr-calculator': 'mr-calculator' })
            .then(response => {
                if (checkApiStatus(response)) {
                    setEngineTypeList(response.data.data.engineTypes)
                }
            });
    }
    return (
        <div style={{ position: 'relative' }}>
            {processingLoader ?
                <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', zIndex: 10, background: 'rgba(255,255,255,.8)' }}>
                    <p style={{ textAlign: 'center', fontSize: '15px', padding: '7px 0' }}><span style={{ padding: '4px', background: '#fff', fontWeight: '500', fontFamily: 'Conv_IBMPlexSans-Medium' }}>Processing the Graph Data...</span></p>
                </div> : null
            }
             <Grid container spacing={1} style={{ display: 'inline-flex', alignItems: 'center',padding:'' }}>
                    <Grid item xs={12} md={6} lg={2}>
                    <Autocomplete
                        options={engineTypeList}
                        multiple
                        disableClearable
                        limitTags={2}
                        getOptionLabel={option => option.name}
                        value={filter?.engine_type || []}
                        //style={{ width: 345 }}
                        id="engine_type"
                        onChange={(e, value) => changeGenericFilter('engine_type', value)}
                        renderInput={params => <TextField margin="normal"  {...params} InputLabelProps={{ shrink: 'true' }} label="Engine Type" placeholder={filter?.engine_type?.length ? "" : "Select Engine Type"} variant="outlined" />}
                    />
                    </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Autocomplete
                        // disabled={filter?.engine_type?.length > 1}
                        options={utilizationList}
                        getOptionLabel={option => option.label}
                        id="utlizationRatio"
                        value={filter.utlizationRatio || null}
                        onChange={(e, value) => { changeGenericFilter('utlizationRatio', value); }}
                        renderInput={params => <TextField margin="normal"  {...params} label="Utilization Ratio" placeholder="Select  Ratio" InputLabelProps={{ shrink: true }} variant="outlined" />}
                    />
                    </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Autocomplete
                        options={[{ label: '1st Run', value: 1 }, { label: '2nd Run', value: 2 }, { label: 'Mature Run', value: 3 }]}
                        getOptionLabel={option => option.label}
                        id="run_id"
                        disableClearable={true}
                        value={filter.run_id || null}
                        onChange={(e, value) => { changeGenericFilter('run_id', value); }}
                        renderInput={params => <TextField margin="normal"  {...params} label="Run Type" placeholder="Select Run Type" InputLabelProps={{ shrink: true }} variant="outlined" />}
                    />
                    </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    {filter?.engine_type?.length >= 1 ?
                        <GoogleLocationField
                            onFieldChange={(event, key, value) => changeGenericFilter('location', getAddressLocationDetails(value))}
                            location={filter?.location?.address ? filter.location.address : ''}
                        /> :
                        <TextField margin="normal" disabled
                         label={
                            <div style={{ position: 'relative', top: '-14px' }}>
                                Location
                                <Tooltip title={`Select engine type to select Location.`} placement="top" arrow>
                                    <InfoIcon fontSize="small" style={{ position: 'relative', top: "3px", marginLeft: '4px' }} />
                                </Tooltip>
                            </div>
                        }
                         placeholder="Select Places" InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }} variant="outlined" />
                    }
                    </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Autocomplete
                        options={derateList}
                        disabled={filter?.engine_type?.length >= 1 && filter?.location?.address ? false : true}
                        getOptionLabel={option => option.value}
                        id="derate"
                       // style={{ width: 150 }}
                        value={filter.derate || null}
                        onChange={(e, value) => { changeGenericFilter('derate', value); }}
                        renderInput={params => <TextField margin="normal" {...params}
                            label={
                                <div style={{ position: '', top: '' }}>
                                    Derate
                                    <Tooltip title={`Select location to select Derate.`} placement="top" arrow>
                                        <InfoIcon fontSize="small" style={{ position: '', top: "", marginLeft: '4px' }} />
                                    </Tooltip>
                                </div>
                            }
                            placeholder="Select Derate" InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }} variant="outlined" />}
                    />
                    </Grid>
                {/* </li> */}
                {/* <li className='list-inline-item margin-right'> */}
                <Grid item xs={12} md={6} lg={2}>
                    <Autocomplete
                        options={[{ label: 'On wing life vs Cost', value: 1 }, { label: 'On wing life vs MR', value: 2 }, { label: 'Cost vs MR', value: 3 }]}
                        getOptionLabel={option => option.label}
                        id="graph_comparision"
                        getOptionSelected={(option, val) => option.value == val.value}
                       // style={{ width: 180 }}
                        disableClearable={true}
                        value={filter.graph_comparision || null}
                        onChange={(e, value) => { changeGenericFilter('graph_comparision', value); }}
                        renderInput={params => <TextField margin="normal"  {...params} label="Graph Type" placeholder="Select Graph Type" InputLabelProps={{ shrink: true }} variant="outlined" />}
                    />
                    </Grid>
                {/* </li> */}
                {/* <li className='list-inline-item margin-right apply-btn'> */}
                <Grid item xs={12} lg={2} >
                    <Button fullWidth variant='contained' color='primary' size='small' onClick={() => getResponseBack(filter)} >
                        Apply
                    </Button>
                    </Grid>
                {/* </li> */}
            {/* </ul> */}
        </Grid>
        </div>
    )
}

import React, { Fragment, Component } from 'react';
import { Button, TableRow, TableCell, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, FormControl, RadioGroup, FormControlLabel, Radio, InputAdornment, Tooltip, makeStyles } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { withSnackbar } from 'notistack';
import { Link, withRouter } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import { TableListComp, ExportManu, PageLoader } from '../../../shared_elements';
import { getCurrencyFormat, getLocalStorageInfo, checkApiStatus } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
import InfoIcon from '@material-ui/icons/Info';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { selectLLPToReplaceApi, replacedTheLLPSelectedApi, workScopeLLpValidationFn, exportLLPListApi } from '../apiServices';
class WorkScopeLLP extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageLoader: false,
      workScopeLLPInfo: {
        llps: [],
        cyclesRemaining: []
      },
      workscopeError: {}
    }
    this.selectLLPToReplaceApi = selectLLPToReplaceApi.bind(this)
    this.replacedTheLLPSelectedApi = replacedTheLLPSelectedApi.bind(this)
    this.workScopeLLpValidationFn = workScopeLLpValidationFn.bind(this)
    this.exportLLPListApi = exportLLPListApi.bind(this)
  }
  toggleModalFn = () => {
    this.setState({
      workScopeLLPInfo: {
        llps: [],
        cyclesRemaining: []
      },
    })
  }
  showLLPReplaceFn = () => {
    const { shopVisit, engineIndex, engine, wk } = this.props
    this.selectLLPToReplaceApi(
      this.props,
      { shop_visit_no: shopVisit, log_id: this.props.match.params.id, engine_no: engineIndex },
      engine.workscopes[wk]
    )
  }
  exportReportFn = (extension) => {
    const { engine, wkIndex } = this.props
    const { workScopeLLPInfo } = this.state
    this.exportLLPListApi(this.props, { log_id: this.props.match.params.id, engine_no: workScopeLLPInfo.query.engine_no, shop_visit_no: workScopeLLPInfo.query.shop_visit_no, download: extension }, engine.workscopes[wkIndex].name)
  }
  updateLLPToReplaceFn = (flag, index, data) => {
    if (data.cycles_remaining && data.remaining_on_new) {
      if (data.cycles_remaining == data.remaining_on_new) {
        this.setState(prevState => ({
          ...prevState,
          workScopeLLPInfo: {
            ...prevState.workScopeLLPInfo,
            llps: prevState.workScopeLLPInfo.llps.map((llp, llpIndex) => llpIndex === index ?
              {
                ...llp,
                replaced: flag,
                cycles_remaining_out: llp.max_limit,
                cycles_remaining_in: llp.cycles_remaining
              } : llp)
          }
        }))
      } else {
        this.setState(prevState => ({
          ...prevState,
          workScopeLLPInfo: {
            ...prevState.workScopeLLPInfo,
            llps: prevState.workScopeLLPInfo.llps.map((llp, llpIndex) => llpIndex === index ?
              {
                ...llp,
                cycles_remaining_out: llp.cycles_remaining,
                cycles_remaining_in: llp.remaining_on_new
              } : llp)
          }
        }))
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        workScopeLLPInfo: {
          ...prevState.workScopeLLPInfo,
          llps: prevState.workScopeLLPInfo.llps.map((llp, llpIndex) => llpIndex === index ?
            {
              ...llp,
              cycles_remaining_out: llp.llp_build_goal ? llp.llp_build_goal : llp.max_limit,
              csn_out: 0,
              cycles_remaining_in: llp.cycles_remaining
            } : llp)
        }
      }))
    }
    this.setState(prevState => ({
      ...prevState,
      workScopeLLPInfo: {
        ...prevState.workScopeLLPInfo,
        llps: prevState.workScopeLLPInfo.llps.map((llp, llpIndex) => llpIndex === index ? { ...llp, replaced: flag, edited_list_price: llp.price } : llp)
      }
    }))
    if (flag == 0) {
      this.setState(prevState => ({
        ...prevState,
        workScopeLLPInfo: {
          ...prevState.workScopeLLPInfo,
          llps: prevState.workScopeLLPInfo.llps.map((llp, llpIndex) => llpIndex === index ? {
            ...llp,
            cycles_remaining_out: llp.remaining_on_new || llp.remaining_on_new >= 0 ? llp.remaining_on_new : llp.cycles_remaining_in ? llp.cycles_remaining_in : '--'
          } : llp)
        }
      }))
    }
  }
  updateLlpOnchangeFn = (event, keyParam, value, llpIndex, engine, item) => {
    this.updateLlpOnchangeFnForReplacedLLP(keyParam, value, llpIndex)
    if (keyParam == "cycles_remaining_out") {
      this.updateLlpOnchangeFnForReplacedLLP('csn_out', value ? item.max_limit - parseInt(value) : 0, llpIndex)
      this.updateLlpOnchangeFnForReplacedLLP('llp_reimbursement',
        parseFloat((
          value -
          (item.remaining_on_new || item.remaining_on_new >= 0 ? item.remaining_on_new : item.cycles_remaining_in)) *
          ((engine.stub_life_list_price * item.price)
            / (engine.stub_life_limit * item.edited_max_limit))).toFixed(2),
        llpIndex)
      this.updateLlpOnchangeFnForReplacedLLP('edited_list_price', parseFloat((item.price / item.edited_max_limit) * value).toFixed(2), llpIndex)
      // this.updateLlpOnchangeFnForReplacedLLP('cycles_remaining_in', value ? item.edited_max_limit - parseInt(value) : 0, llpIndex)
      this.updateLlpOnchangeFnForReplacedLLP('csn', value ? item.edited_max_limit - parseInt(value) : 0, llpIndex)
      this.updateLlpOnchangeFnForReplacedLLP('csn_out', value ? item.edited_max_limit - parseInt(value) : 0, llpIndex)

    }
    if (keyParam === "edited_max_limit") {
      this.updateLlpOnchangeFnForReplacedLLP('cycles_remaining_out', value, llpIndex)
      this.updateLlpOnchangeFnForReplacedLLP('edited_max_limit', value, llpIndex)
    }

  }
  updateLlpOnchangeFnForReplacedLLP = (keyParam, value, llpIndex,) => {
    this.setState(prevState => ({
      ...prevState,
      workScopeLLPInfo: {
        ...prevState.workScopeLLPInfo,
        llps: prevState.workScopeLLPInfo.llps.map((llp, index) => index === llpIndex ?
          {
            ...llp,
            [keyParam]: value,
          } : llp)
      }
    }))
  }
  updateWorkScopeError = (key, message, llpIndex) => {
    this.setState(prevState => ({
      ...prevState,
      workscopeError: {
        ...prevState.workscopeError,
        [llpIndex]: {
          ...prevState.workscopeError[llpIndex],
          [key]: message
        }
      }
    }))
  }
  replacedTheLLPSelectedFn = (props, data) => {
    let validationInputs = {}
    data.llps.map((item, index) => {
      if (item && item.replaced_llp_type && item.replaced_llp_type.id && item.replaced_llp_type.id == 2) {
        validationInputs = {
          ...validationInputs,
          [index]: this.workScopeLLpValidationFn(item, index)
        }
      }
    })
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
      let payload = {
        shop_visit_no: data.query.shop_visit_no,
        engine_no: data.query.engine_no,
        log_id: data.query.log_id,
        current_replaced_llps: data.llps.filter(llp => llp.replaced === 1).map(item => ({ ...item, cycles_remaining: item.cycles_remaining_out ? parseInt(item.cycles_remaining_out) : parseInt(item.cycles_remaining), edited_max_limit: parseInt(item.edited_max_limit) }))
      }
      this.setState({ pageLoader: true })
      this.replacedTheLLPSelectedApi(props, payload)
        .then(response => {
          if (checkApiStatus(response)) {
            this.setState({
              workScopeLLPInfo: {
                modal: false,
                llps: []
              }
            })
            this.props.getResponseBack()
            this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          } else {
            this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
          this.setState({ pageLoader: false })
        })
    } else {
      this.setState({
        workscopeError: validationInputs
      })
    }
  }
  render() {
    const { engine, isLocked } = this.props;
    const { workScopeLLPInfo, workscopeError, pageLoader } = this.state;
    let sumReplacement = workScopeLLPInfo.llps.map(item => item.replaced == 1 && item.edited_list_price >= 0 && item.edited_list_price ? parseFloat(item.edited_list_price) : 0).reduce((a, b) => a + b, 0)
    const workScopeLLPHd = [
      { label: '', sortOption: false },
      { label: 'LLP Modules', sortOption: false },
      { label: 'Description', sortOption: false },
      { label: 'CSN', sortOption: false },
      { label: 'Cycle Limit', sortOption: false },
      { label: 'Cycles Remaining', sortOption: false },
      { label: 'Cycles for Next Run', sortOption: false },
      { label: 'Life (%) Remaining', sortOption: false },
      { label: `${workScopeLLPInfo && workScopeLLPInfo.cost_of_year && workScopeLLPInfo.cost_of_year.length === 1 ? `Typical Cost as per ${workScopeLLPInfo.cost_of_year[0]} (USD)` : 'Typical Cost (USD)'}`, sortOption: false, tooltip: true },
      { label: 'Reimbursement Cost', sortOption: false, tooltip1: true },
      { label: 'Stub Life Discarded Cost', sortOption: false, tooltip2: true },
      { label: 'Commercial Value', sortOption: false, actionCell: true, tooltip3: true },
    ];
    return (
      <Fragment>
        <span style={{ paddingLeft: '5px', cursor: 'pointer' }} onClick={this.showLLPReplaceFn}><AddCircleIcon fontSize="small" style={{position:'relative', top:'4px', left:'1px'}} color="primary" /></span>
        <Dialog
          open={workScopeLLPInfo.modal}
          onClose={this.toggleModalFn}
          aria-labelledby="scroll-dialog-title"
          fullScreen
        >
          <DialogTitle id="scroll-dialog-title">
            {isMobile ? <h5 style={{ fontWeight: '500', }}>Life Limited Part(s) Replacement</h5> : 'Life Limited Part(s) Replacement'}<ExportManu title="Export" files={[{ title: 'Excel', extension: 'xlsx', key: '' }]} exportReportFn={(file) => this.exportReportFn(file.extension)} />
          </DialogTitle>
          <DialogContent dividers={true} style={{padding:'8px'}} className="workscope-llp">
            <div className="fixed-table"   >
              <TableListComp
                heads={workScopeLLPHd}
                tooltipContent='Cost of the LLP'
                tooltipContent2='The Total amount reimbursed based on the MR collection made'
                tooltipContent3='The cost of the LLP discarded based on stub life limit and stub life list price'
                tooltipContent4='The cost of the LLP based on the commercial value cut off'
                data={workScopeLLPInfo.llps.map((item, index) => {
                  let style = {}
                  if (item.replaced != 1) {
                    if (item.cycles_remaining < item.cycles_for_next_run)
                      style = {
                        ...style,
                        color: 'rgb(189, 34, 56)',

                      }
                  }
                  return (
                    <TableRow hover tabIndex={-1} style={Math.min(...workScopeLLPInfo.cyclesRemaining) == item.cycles_remaining && item.replaced != 1 ? { background: 'rgb(253 234 234)', whiteSpace: 'nowrap', } : {}}   >
                      <TableCell style={{ width: '40px' }}>
                        <Checkbox
                          color="primary"
                          disabled={isLocked}
                          checked={item.replaced === 1 ? true : false}
                          onChange={(e) => getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 ?
                            this.updateLLPToReplaceFn(e.target.checked ? 1 : 0, index, item)
                            : e.preventDefault()
                          }
                          value="LLPStatus" />
                      </TableCell>
                      <TableCell style={style}>
                        {
                          item.module == 'Fan' ?
                            <span style={{ color: 'rgb(74, 153, 254)' }}>
                              {item.module ? item.module : '--'}
                            </span> :
                            item.module == 'Core' ?
                              <span style={{ color: 'rgb(93, 173, 94)' }}>
                                {item.module ? item.module : '--'}
                              </span> :
                              item.module == 'LPT' ?
                                <span style={{ color: 'rgb(252, 152, 37)' }}>
                                  {item.module ? item.module : '--'}
                                </span> : null
                        }
                      </TableCell>
                      <TableCell style={style}>
                        {item.part_name} <br />
                        {item.replaced != 1 && item.cycles_remaining < item.cycles_for_next_run ? <span style={{ fontSize: '10px', padding: '2px 4px', color: '#000', borderRadius: '3px', background: '#fff1b6' }}>Next Run Life will be Restricted </span> : null}
                      </TableCell>
                      <TableCell style={style}>
                        {item.csn}
                      </TableCell>
                      <TableCell style={{ ...style, width: '100px' }}>
                        {
                          item.replaced ?
                            <TextField
                              id="edited_max_limit"
                              fullWidth
                              value={item.edited_max_limit}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => { this.updateLlpOnchangeFn(e, 'edited_max_limit', e.target.value, index, engine, item) }}
                              error={workscopeError && workscopeError[index] && workscopeError[index].edited_max_limit ? true : false}
                              helperText={workscopeError && workscopeError[index] && workscopeError[index].edited_max_limit ? workscopeError[index].edited_max_limit : ''}
                              onFocus={() => this.updateWorkScopeError('edited_max_limit', '', index)}
                              style={{ width: '100px' }}

                            /> :
                            !getLocalStorageInfo().user.permission.mr_calculator.is_saas ?
                              <div style={{ width: '100px', whiteSpace: 'pre-line' }}>
                                {item.max_limit}
                              </div> : null
                        }
                      </TableCell>
                      <TableCell style={{ ...style, width: '200px' }}>
                        <ul className="list-inline" style={{ width: '200px', display: 'flex', alignItems: 'centre' }}>
                          <li className="list-inline-item" style={{ minWidth: '60px', marginRight: '20px', display: 'flex' }}>
                            <div className="in-value">
                              <p>In</p>
                              <h6>{item.remaining_on_new || item.remaining_on_new >= 0 ? item.remaining_on_new : item.cycles_remaining_in ? item.cycles_remaining_in : '--'}</h6>
                            </div>
                          </li>
                          <li className="list-inline-item" style={{ width: '100px' }}>
                            <TextField
                              id="cycles_remaining"
                              fullWidth
                              label="Out"
                              margin="none"
                              value={item.cycles_remaining_out}
                              disabled={!item.replaced}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ maxLength: 6 }}
                              onChange={(e) => {
                                regexConstants.onlyNumeric.test(e.target.value) && e.target.value <= parseInt(item.edited_max_limit)
                                  ? this.updateLlpOnchangeFn(e, 'cycles_remaining_out', e.target.value, index, engine, item) : e.preventDefault();
                              }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">FC</InputAdornment>
                              }}
                            />
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell style={{ ...style, width: '100px' }}>
                        {item.cycles_for_next_run}
                      </TableCell>
                      <TableCell style={{ ...style, width: '200px' }}>
                        <ul className="list-inline" style={{ width: '200px', display: 'flex', alignItems: 'centre' }}>
                          <li className="list-inline-item" style={{ paddingRight: '15px' }}>
                            <span className="in-value">
                              <p>In</p>
                              {
                                item.remaining_on_new || item.remaining_on_new === 0  ? <h6>{(item.remaining_on_new / item.max_limit * 100).toFixed(2)} %</h6> :
                                  <h6>{(item.cycles_remaining / item.max_limit * 100).toFixed(2)} %</h6>
                              }
                            </span>
                          </li>
                          <li className="list-inline-item">
                            <span
                              className="in-value" >
                              <p>Out</p>
                              {
                                item.replaced ?
                                  item.edited_max_limit ?
                                    <h6>{(item.cycles_remaining_out / item.edited_max_limit * 100).toFixed(2)} %</h6> : <h6>0%</h6> :
                                  item.remaining_on_new ? <h6>{(item.remaining_on_new / item.max_limit * 100).toFixed(2)} %</h6> :
                                    <h6>{(item.cycles_remaining / item.max_limit * 100).toFixed(2)} %</h6>
                              }
                            </span>
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell style={style}>
                        {
                          item.replaced ?
                            <TextField
                              id="edited_list_price"
                              fullWidth
                              value={item.edited_list_price}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.updateLlpOnchangeFn(e, 'edited_list_price', e.target.value, index, engine, item) : e.preventDefault() }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                                endAdornment: <InputAdornment position="end"><Tooltip size="small" color="primary" title={<div><p>New Part Price : {<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.price}</NumericLabel>}</p><br /><p>Difference:{<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{parseFloat(item.price - item.edited_list_price)}</NumericLabel>} </p><br /><p>{workScopeLLPInfo.cost_of_year && workScopeLLPInfo.cost_of_year >= 0 ? null : 'As Per Year :' + item.year_of_cost}</p></div>} placement="top" arrow>
                                  <InfoIcon color="default" style={{ verticalAlign: 'bottom', marginLeft: '3px', fontSize:'14px' }} />
                                </Tooltip></InputAdornment>
                              }}
                              error={workscopeError && workscopeError[index] && workscopeError[index].edited_list_price ? true : false}
                              helperText={workscopeError && workscopeError[index] && workscopeError[index].edited_list_price ? workscopeError[index].edited_list_price : ''}
                              onFocus={() => this.updateWorkScopeError('edited_list_price', '', index)}
                              style={{ width: '150px' }}

                            /> :
                            !getLocalStorageInfo().user.permission.mr_calculator.is_saas ?
                              <div style={{ width: '200px', whiteSpace: 'pre-line' }}>
                                <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.price}</NumericLabel>
                                {item.year_of_cost && workScopeLLPInfo.cost_of_year.length > 1 ?
                                  <span> ( as per {item.year_of_cost ? item.year_of_cost : '2018'} )</span> : null
                                }
                              </div> : null
                        }
                      </TableCell>
                      <TableCell style={{ ...style, width: '150px' }} >
                        {item.llp_reimbursement ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.llp_reimbursement}</NumericLabel> : '--NA--'}
                      </TableCell>
                      <TableCell style={{ ...style, width: '100px' }}>
                        {
                          item.stub_life_discarded ?
                            <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>
                              {item.stub_life_discarded_cost}
                            </NumericLabel>
                            : '--NA--'
                        }
                      </TableCell>
                      <TableCell style={{ ...style, width: '100px' }}>
                        {item.commercial_value ?
                          <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>
                            {item.commercial_value}
                          </NumericLabel>
                          : <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>
                            0
                          </NumericLabel>}
                      </TableCell>
                    </TableRow>
                  )
                }
                )}
              />
            </div>
          </DialogContent>
          <DialogActions style={{ marginLeft: '60px' }}>
            <Grid container spacing={2}>
              <Grid item xs={4} sm={6} md={2}>
                {!getLocalStorageInfo().user.permission.mr_calculator.is_saas ?
                  <div>
                    {isMobile ? <p style={{ fontSize: '13px' }}>Total Replacement Cost</p> : <p>Total Replacement Cost</p>}
                    <NumericLabel params={getCurrencyFormat({ shortFormat: false })} style={isMobile ? { fontSize: '14px' } : {}}>
                      {sumReplacement}
                    </NumericLabel>
                  </div> : null
                }
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                {!getLocalStorageInfo().user.permission.mr_calculator.is_saas ?
                  <div>
                    {isMobile ? <p style={{ fontSize: '13px' }}>Total Reimbursement Cost</p> : <p>Total Reimbursement Cost</p>}
                    <NumericLabel params={getCurrencyFormat({ shortFormat: false })} style={isMobile ? { fontSize: '14px' } : {}}>
                      {workScopeLLPInfo.llps.reduce((total, next) =>
                        total + (next.llp_reimbursement ? parseFloat(next.llp_reimbursement) : 0), 0)
                      }
                    </NumericLabel>
                  </div> : null
                }
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                {!getLocalStorageInfo().user.permission.mr_calculator.is_saas ?
                  <div>
                    {isMobile ? <p style={{ fontSize: '13px' }}>Total Stub Life Discarded Cost</p> : <p>Total Stub Life Discarded Cost</p>}
                    <NumericLabel params={getCurrencyFormat({ shortFormat: false })} style={isMobile ? { fontSize: '14px' } : {}}>
                      {workScopeLLPInfo.llps.reduce((total, next) =>
                        total + (next.stub_life_discarded && next.stub_life_discarded_cost ? next.stub_life_discarded_cost : 0), 0)
                      }
                    </NumericLabel>
                  </div> : null
                }
              </Grid>
              <Grid item xs={6} sm={6} md={5}>
                <div style={{ float: 'right' }}>
                  {
                    getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked ?
                      <Button color="primary" onClick={() => this.replacedTheLLPSelectedFn(this.props, workScopeLLPInfo)} variant="contained">Save</Button> : null
                  }
                  <Button color="primary" onClick={this.toggleModalFn}>{isLocked ? 'Close' : 'Cancel'}</Button>
                </div>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        {pageLoader ? <PageLoader /> : null}
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(WorkScopeLLP));

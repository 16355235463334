import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { checkApiStatus, getLocalStorageInfo } from '../../../utils';
import { Tabs, Tab, Button, Grid, Paper, Tooltip, Breadcrumbs, Typography, TextField, Table, TableRow, TableCell, Container, Drawer, Hidden } from '@material-ui/core';
import { PageLoader } from '../../../shared_elements';
import { Comments, LogRefNo, AirframeCard, APUCard, LGCard, EngineCard, WorkScopeModal, LOICalModal, EngineShopVisits } from '../components';
import { trackActivity } from '../../../utils/mixpanel'
import lockIcon from '../../../assets/images/NewLockIcon.png';
import unlockIcon from '../../../assets/images/unlockicon.png';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { loiMrApi, deRateVariableApi, getMrLogDetailApi, apuUpdateUsageApi, lgUpdateUsageApi, airframeUpdateUsageApi, cancelUsageUpdateApi, engineUsageCalApi, carryOverNSVApi, selectLLPToReplaceApi, replacedTheLLPSelectedApi, changeWorkScopeApi, exportLLPListApi, updateMrRateShopVisitApi, validateUsageApi, lgUpdateUsageFn, apuUpdateUsageFn, engineUsageCalFn, updateLLpCycleLimitApi, updateLockApi, updateWorkscopeFn, getEolConditionApi, getEolScenariosApi, addEolScenariosApi, deleteEolScenariosApi, updateEngineTypeApi } from '../apiServices';
import { eoiErrorCode, genericErrorCode } from '..';
import EOICard from '../components/EOICard';
import { DeletePopUp } from '../../../shared_elements';
import { getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import { connect } from 'react-redux';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { fieldValidation, dateValidation, dateRangeValidation } from '../../../utils/formValidation';
import { backendDateFormat } from '../../../constants';
import EditRegionModal from '../components/EditRegionModal';
import { globalGetService } from '../../../utils/globalApiServices';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
class IntermediateMrCal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      left: false,
      eolErrors: [],
      deleteEOLContent: {},
      refNoModal: false,
      assemblyIndex: 'airframe',
      pageLoader: false,
      type: 'view',
      typeEngine: 'view',
      tabIndex: 0,
      mrLogDetail: {},
      eol_scenarios: [],
      eol_conditionList: [],
      removeList: {
        modal: false,
        data: {}
      },
      error: {},
      airEngineError: {},
      genericError: {},
      workscopeError: {},
      workScopeInfo: {
        modal: false,
        data: {}
      },
      workScopeLLPInfo: {
        modal: false,
        llps: [],
        llpCopy: []
      },
      deRateList: [],
      loi: [],
      derate: {},
      airframeEdit: false,
      apuEdit: false,
      landingGearEdit: false,
      enginesEdit: false,
      remarksEdit: false,
      refEdit: false,
      apuInfoPopup: false,
      lgInfoPopup: false,
      airframeInfoPopup: false,
      editedSvCardFlag: false,
      lockEdit: false,
      warningModal: false,
      lgTabIndex: 0,
      engineTypes: []
    }
    this.getMrLogDetailApi = getMrLogDetailApi.bind(this);
    this.getEolConditionApi = getEolConditionApi.bind(this);
    this.getEolScenariosApi = getEolScenariosApi.bind(this);
    this.addEolScenariosApi = addEolScenariosApi.bind(this);
    this.deleteEolScenariosApi = deleteEolScenariosApi.bind(this);
    this.apuUpdateUsageApi = apuUpdateUsageApi.bind(this);
    this.apuUpdateUsageFn = apuUpdateUsageFn.bind(this);
    this.lgUpdateUsageApi = lgUpdateUsageApi.bind(this);
    this.lgUpdateUsageFn = lgUpdateUsageFn.bind(this);
    this.airframeUpdateUsageApi = airframeUpdateUsageApi.bind(this);
    this.cancelUsageUpdateApi = cancelUsageUpdateApi.bind(this);
    this.carryOverNSVApi = carryOverNSVApi.bind(this);
    this.selectLLPToReplaceApi = selectLLPToReplaceApi.bind(this);
    this.replacedTheLLPSelectedApi = replacedTheLLPSelectedApi.bind(this);
    this.changeWorkScopeApi = changeWorkScopeApi.bind(this);
    this.exportLLPListApi = exportLLPListApi.bind(this);
    this.engineUsageCalApi = engineUsageCalApi.bind(this);
    this.engineUsageCalFn = engineUsageCalFn.bind(this);
    this.updateMrRateShopVisitApi = updateMrRateShopVisitApi.bind(this);
    this.validateUsageApi = validateUsageApi.bind(this);
    this.updateLLpCycleLimitApi = updateLLpCycleLimitApi.bind(this);
    this.updateLockApi = updateLockApi.bind(this);
    this.replacedTheLLPSelectedApi = replacedTheLLPSelectedApi.bind(this)
    this.updateWorkscopeFn = updateWorkscopeFn.bind(this)
    this.deRateVariableApi = deRateVariableApi.bind(this);
    this.loiMrApi = loiMrApi.bind(this);
    this.updateEngineTypeApi = updateEngineTypeApi.bind(this);

  }

  componentDidMount() {
    this.getMrLogDetailApi(this.props);
    this.getEngineTypeApi(this.props);
    this.getEolScenariosApi(this.props)
    this.props.getPlatformConstants();
    this.getEolConditionApi(this.props)
    trackActivity('Viewed Log', { page_title: `Intermediate`, log_id: this.props.match.params.id })
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message
      }
    }))
  }
  updateGenericErrorField = (key, message, assembly, index) => {
    if (assembly == 'lg') {
      this.setState(prevState => ({
        ...prevState,
        genericError: {
          ...prevState.genericError,
          [index]: {
            ...prevState.genericError[index],
            [key]: ''
          }
        }
      }))
    }
    else {
      this.setState(prevState => ({
        ...prevState,
        genericError: {
          ...prevState.genericError,
          [key]: message
        }
      }))
    }
  }
  updateAirEngineError = (label, key, message) => {
    this.setState(prevState => ({
      ...prevState,
      airEngineError: {
        ...prevState.airEngineError,
        [label]: {
          ...prevState.airEngineError[label],
          [key]: message
        }
      }
    }))
  }
  updateWorkScopeError = (key, message, llpIndex) => {
    this.setState(prevState => ({
      ...prevState,
      workscopeError: {
        ...prevState.workscopeError,
        [llpIndex]: {
          ...prevState.workscopeError[llpIndex],
          [key]: message
        }
      }
    }))
  }
  onChangeOtherCost = (e, keyParam, value, label, index) => {
    this.setState(prevState => ({
      ...prevState,
      mrLogDetail: {
        ...prevState.mrLogDetail,
        airframe: {
          ...prevState.mrLogDetail.airframe,
          [label]: {
            ...prevState.mrLogDetail.airframe[label],
            other_cost_details: prevState.mrLogDetail.airframe[label].other_cost_details.map((item, itemIndex) => itemIndex === index ?
              {
                ...item,
                [keyParam]: value
              }
              : item)
          }
        }
      },
      airEngineError: {
        ...prevState.airEngineError,
        [label]: {
          ...prevState.airEngineError[label],
          otherCostError: ''
        }
      }
    }))
  }
  onAddRemoveOtherCost = (opsType, label, index) => {
    if (opsType === 'add') {
      this.setState(prevState => ({
        ...prevState,
        mrLogDetail: {
          ...prevState.mrLogDetail,
          airframe: {
            ...prevState.mrLogDetail.airframe,
            [label]: {
              ...prevState.mrLogDetail.airframe[label],
              other_cost_details: [
                ...prevState.mrLogDetail.airframe[label].other_cost_details,
                {
                  id: null,
                  cost: '',
                  cost_type: '',
                  include_in_mr: false
                }
              ]
            }
          }
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        mrLogDetail: {
          ...prevState.mrLogDetail,
          airframe: {
            ...prevState.mrLogDetail.airframe,
            [label]: {
              ...prevState.mrLogDetail.airframe[label],
              other_cost_details: prevState.mrLogDetail.airframe[label].other_cost_details.filter((item, itemIndex) => itemIndex !== index)
            }
          }
        },
        airEngineError: {
          ...prevState.airEngineError,
          [label]: {
            ...prevState.airEngineError[label],
            otherCostError: ''
          }
        }
      }))
    }
  }
  onFieldChange = (e, keyParam, value, tableType, eventType) => {
    const { mrLogDetail } = this.state;
    if (['apu', 'landingGear'].includes(tableType)) {
      if (keyParam == "nextShopvisitDate") {
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail: {
            ...prevState.mrLogDetail,
            [tableType]: {
              ...prevState.mrLogDetail[tableType],
              [keyParam]: value,
              nsv_update: true
            }
          }
        }))
      } else {
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail: {
            ...prevState.mrLogDetail,
            [tableType]: {
              ...prevState.mrLogDetail[tableType],
              [keyParam]: value
            }
          }
        }));
      }

    } else if (['engines', 'airframe'].includes(tableType)) {
      if (tableType === 'engines' && keyParam === 'hour_util') {
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail: {
            ...prevState.mrLogDetail,
            [tableType]: {
              ...prevState.mrLogDetail[tableType],
              [eventType]: {
                ...prevState.mrLogDetail[tableType][eventType],
                [keyParam]: value,
                cycle_util: value ? Math.round(value / mrLogDetail.generalInfo.ratio) : 0
              }
            }
          }
        }));
      } else if (tableType === 'engines' && keyParam === 'engineLLPStatus' && value) {
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail: {
            ...prevState.mrLogDetail,
            [tableType]: {
              ...prevState.mrLogDetail[tableType],
              [eventType]: {
                ...prevState.mrLogDetail[tableType][eventType],
                [keyParam]: value,
                llpLimits: []
              }
            }
          }
        }));
      } else if (keyParam == "nextShopvisitDate") {
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail: {
            ...prevState.mrLogDetail,
            [tableType]: {
              ...prevState.mrLogDetail[tableType],
              [eventType]: {
                ...prevState.mrLogDetail[tableType][eventType],
                [keyParam]: value,
                nsv_update: true
              }
            }
          }
        }))
      } else {
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail: {
            ...prevState.mrLogDetail,
            [tableType]: {
              ...prevState.mrLogDetail[tableType],
              [eventType]: {
                ...prevState.mrLogDetail[tableType][eventType],
                [keyParam]: value
              }
            }
          }
        }));
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        mrLogDetail: {
          ...prevState.mrLogDetail,
          [tableType]: {
            ...prevState.mrLogDetail[tableType],
            [keyParam]: value
          }
        }
      }));
    }
  }
  onEoiFieldChange = (key, value, index) => {
    if (key == 'lease_end_date' || key == 'lease_start_date') {
      let leaseDuration = null
      if (key == 'lease_end_date' && this.state.eol_scenarios[index].lease_start_date) {
        leaseDuration = moment(value).diff(moment(this.state.eol_scenarios[index].lease_start_date), 'months', true)
      }
      else if (key == 'lease_start_date' && this.state.eol_scenarios[index].lease_end_date) {
        leaseDuration = moment(this.state.eol_scenarios[index].lease_end_date).diff(moment(value), 'months', true)
      }
      leaseDuration = Math.round(leaseDuration)
      this.setState(prevState => ({
        ...prevState,
        eol_scenarios:
          prevState.eol_scenarios.map((item, e) => e === index ? { ...prevState.eol_scenarios[index], ['lease_duration']: leaseDuration } : item),
      }))
    }
    if (key == 'lease_duration' && this.state.eol_scenarios[index].lease_start_date) {
      let endDate = moment(this.state.eol_scenarios[index].lease_start_date).add(value, 'M').format(backendDateFormat)
      this.setState(prevState => ({
        ...prevState,
        eol_scenarios:
          prevState.eol_scenarios.map((item, e) => e === index ? { ...prevState.eol_scenarios[index], ['lease_end_date']: endDate } : item)
      }))
    }
    this.setState(prevState => ({
      ...prevState,
      eol_scenarios:
        prevState.eol_scenarios.map((item, e) => e === index ? { ...prevState.eol_scenarios[index], [key]: value } : item),
    }))
  }
  onDeleteEOLContent = (leaseIndex, eolIndex) => {
    this.setState(prevState => ({
      ...prevState,
      deleteEOLContent: { modal: true, leaseIndex, eolIndex }
    }))
  }
  onDeleteEOLContentRow = () => {
    const { deleteEOLContent, eol_scenarios } = this.state;
    if (deleteEOLContent.eolIndex === null) {
      if (this.state.eol_scenarios[deleteEOLContent?.leaseIndex]?.id || this.state.eol_scenarios[deleteEOLContent?.leaseIndex]?.id == 0) {
        this.deleteEolScenariosApi(this.props, { eol_scenarios: [this.state.eol_scenarios[deleteEOLContent?.leaseIndex]?.id], eol_conditions: [] })
      }
      else {
        this.setState(prevState => ({
          ...prevState,
          eol_scenarios: prevState.eol_scenarios.filter((item, itemIndex) => itemIndex !== deleteEOLContent.leaseIndex)
        }))
      }
    } else {
      if (this.state.eol_scenarios[deleteEOLContent?.leaseIndex]?.eol_conditions[deleteEOLContent.eolIndex]?.id || this.state.eol_scenarios[deleteEOLContent?.deleteEOLContent?.leaseIndex]?.eol_conditions[deleteEOLContent.eolIndex]?.id == 0) {
        this.deleteEolScenariosApi(this.props, { eol_scenarios: [], eol_conditions: [eol_scenarios[deleteEOLContent?.leaseIndex].eol_conditions[deleteEOLContent.eolIndex]?.id] })
      }
      else {
        this.setState(prevState => ({
          ...prevState,
          eol_scenarios: prevState.eol_scenarios.map((item, itemIndex) => itemIndex !== deleteEOLContent.leaseIndex ? item : {
            ...item,
            eol_conditions: item.eol_conditions.filter((eol, elIndex) => elIndex !== deleteEOLContent.eolIndex)
          })
        }))
      }
    }
    this.setState({ deleteEOLContent: { modal: false } })
  }

  addNewEolScenarios = (eolState) => {
    if (this.state?.eol_scenarios?.length < 3) {
      this.setState(prevState => ({
        ...prevState,
        eol_scenarios:
          [...prevState.eol_scenarios,
          {
            lease_number: eolState?.length + 1,
            lease_start_date: null,
            lease_type: null,
            lease_end_date: null,
            lease_duration: null,
            id: null,
            eol_conditions: [
              {
                component_name: null,
                value: null,
                id: null,
                expected_mint_condition: null,
              }
            ]
          }]
      }))
    }
    else {
      this.props.enqueueSnackbar('Max 3 Leases are allowed', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  }
  addEoiConditions = (index) => {
    if (this.state?.eol_scenarios[index]?.eol_conditions?.length < this.state?.eol_conditionList?.length) {
      this.setState(prevState => ({
        ...prevState,
        eol_scenarios: prevState.eol_scenarios.map((item, eolIndex) => eolIndex == index ? {
          ...item, eol_conditions: [...item.eol_conditions, {
            component_name: null,
            value: null,
            id: null,
            expected_mint_condition: null,
          }
          ]
        } : item)
      }))
    }
    else {
      this.props.enqueueSnackbar('Max rows are added for this lease', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  }
  onFieldChangeEolCndtn = (leaseIndex, eolConditionIndex, key, value) => {
    this.setState(prevState => ({
      ...prevState,
      eol_scenarios: prevState.eol_scenarios.map((item, eolCndtnIndex) => eolCndtnIndex == leaseIndex ? { ...item, eol_conditions: item.eol_conditions.map((i, rowIndex) => rowIndex == eolConditionIndex ? { ...i, [key]: value } : i) } : item)
    }))
  }
  onFocusEOL = (index, key) => {
    this.setState(prevState => ({
      ...prevState,
      eolErrors:
        prevState.eolErrors.map((item, e) => e === index ? { ...prevState.eolErrors[index], [key]: '' } : item),
    }))
  }
  onFocusEolConidtion = (leaseIndex, eolConditionIndex, key) => {
    this.setState(prevState => ({
      ...prevState,
      eolErrors: prevState.eolErrors.map((item, eolCndtnIndex) => eolCndtnIndex == leaseIndex ? { ...item, conditionError: item.conditionError.map((i, rowIndex) => rowIndex == eolConditionIndex ? { ...i, [key]: '' } : i) } : item)
    }))
  }
  addEditEoiFn = () => {
    const { eol_scenarios } = this.state;
    let accessoriesError = [];
    let payload = Object.assign([], eol_scenarios);
    let validationInputs = payload.map((item, index) => {
      let error = {
        lease_start_date: eoiErrorCode['lease_start_date'][fieldValidation({ ...eoiErrorCode['lease_start_dateObj'], fieldval: item.lease_start_date })],
        lease_end_date: eoiErrorCode['lease_end_date'][fieldValidation({ ...eoiErrorCode['lease_end_dateObj'], fieldval: item.lease_end_date })],
        lease_type: eoiErrorCode['lease_type'][fieldValidation({ ...eoiErrorCode['lease_typeObj'], fieldval: item.lease_type })],
      };
      if (item.lease_start_date && item.lease_end_date) {
        error = {
          ...error,
          lease_start_date: dateRangeValidation(item.lease_start_date, item.lease_end_date, 30),
        }
        if (index > 0 && (moment(eol_scenarios[index - 1]?.lease_end_date, 'YYYY-MM-DD').diff(moment(item.lease_start_date, 'YYYY-MM-DD')) > 0)) {
          error = {
            ...error,
            lease_start_date: 'Lease Start date should be greater than previous lease end date',
          }
        }
      }
      let conditionError = item.eol_conditions.map(childEol => ({
        component_name: eoiErrorCode['component_name'][fieldValidation({ ...eoiErrorCode['component_nameObj'], fieldval: childEol.component_name })],
        expected_mint_condition: eoiErrorCode['expected_mint_condition'][fieldValidation({ ...eoiErrorCode['expected_mint_conditionObj'], fieldval: childEol.expected_mint_condition })],
        value: childEol?.expected_mint_condition?.value == 3 ? eoiErrorCode['value'][fieldValidation({ ...eoiErrorCode['valueObj'], fieldval: childEol.value })] : '',
      }))
      return { ...error, conditionError }
    });
    validationInputs.map(errorItem => {
      const { conditionError, ...rest } = errorItem;
      let e1 = true, e2 = true;
      if (!Object.keys(rest).every((k) => { return rest[k] === '' })) {
        e1 = false
      }
      conditionError.map(data => {
        if (!Object.keys(data).every((k) => { return data[k] === '' })) {
          e2 = false
        }
      });
      accessoriesError.push(e1 && e2);
    })
    if (accessoriesError.includes(false)) {
      this.setState({ eolErrors: validationInputs });
      this.props.enqueueSnackbar('All Mandatory fields are not filled , please check the form', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    } else {
      this.addEolScenariosApi(this.props, eol_scenarios)
      this.setState({ eolErrors: [] })
    };
  }
  updateLLPToReplaceFn = (flag, index, data) => {
    if (data.cycles_remaining && data.remaining_on_new) {
      if (data.cycles_remaining == data.remaining_on_new) {
        this.setState(prevState => ({
          ...prevState,
          workScopeLLPInfo: {
            ...prevState.workScopeLLPInfo,
            llps: prevState.workScopeLLPInfo.llps.map((llp, llpIndex) => llpIndex === index ?
              {
                ...llp,
                replaced: flag,
                cycles_remaining_out: llp.max_limit,
                cycles_remaining_in: llp.cycles_remaining
              } : llp)
          }
        }))
      } else {
        this.setState(prevState => ({
          ...prevState,
          workScopeLLPInfo: {
            ...prevState.workScopeLLPInfo,
            llps: prevState.workScopeLLPInfo.llps.map((llp, llpIndex) => llpIndex === index ?
              {
                ...llp,
                cycles_remaining_out: llp.cycles_remaining,
                cycles_remaining_in: llp.remaining_on_new
              } : llp)
          }
        }))
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        workScopeLLPInfo: {
          ...prevState.workScopeLLPInfo,
          llps: prevState.workScopeLLPInfo.llps.map((llp, llpIndex) => llpIndex === index ?
            {
              ...llp,
              cycles_remaining_out: llp.max_limit,
              cycles_remaining_in: llp.cycles_remaining
            } : llp)
        }
      }))
    }
    this.setState(prevState => ({
      ...prevState,
      workScopeLLPInfo: {
        ...prevState.workScopeLLPInfo,
        llps: prevState.workScopeLLPInfo.llps.map((llp, llpIndex) => llpIndex === index ? { ...llp, replaced: flag, edited_list_price: llp.price } : llp)
      }
    }))
  }
  updateLlpRadioFn = (event, keyParam, value, llpIndex) => {
    let data = {}
    if (value == 1) {
      data = {
        id: 1,
        name: 'New'
      }
    }
    if (value == 2) {
      data = {
        id: 2,
        name: 'Part Life'
      }
    }
    this.setState(prevState => ({
      ...prevState,
      workScopeLLPInfo: {
        ...prevState.workScopeLLPInfo,
        llps: prevState.workScopeLLPInfo.llps.map((llp, index) => index === llpIndex ?
          {
            ...llp,
            [keyParam]: data,
            edited_list_price: value == 2 ? parseFloat((llp.price / llp.max_limit) * llp.cycles_remaining).toFixed(2) : llp.edited_list_price
          } : llp)
      }
    }))
  }
  onLgFieldChange = (index, e, keyParam, value, tableType, eventType) => {
    if (keyParam == "nextShopvisitDate") {
      this.setState(prevState => ({
        ...prevState,
        mrLogDetail: {
          ...prevState.mrLogDetail,
          landingGear: prevState.mrLogDetail.landingGear.map((item, lgindex) => (
            lgindex == index ? { ...item, [keyParam]: value, nsv_update: true } : item
          ))
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        mrLogDetail: {
          ...prevState.mrLogDetail,
          landingGear: prevState.mrLogDetail.landingGear.map((item, lgindex) => (
            lgindex == index ? { ...item, [keyParam]: value } : item
          ))
        }
      }));
    }
  }
  updateLlpOnchangeFn = (event, keyParam, value, llpIndex, engine) => {
    this.setState(prevState => ({
      ...prevState,
      workScopeLLPInfo: {
        ...prevState.workScopeLLPInfo,
        llps: prevState.workScopeLLPInfo.llps.map((llp, index) => index === llpIndex ?
          {
            ...llp,
            [keyParam]: value,
          } : llp)
      }
    }))
    if (keyParam == "cycles_remaining_out") {
      this.setState(prevState => ({
        ...prevState,
        workScopeLLPInfo: {
          ...prevState.workScopeLLPInfo,
          llps: prevState.workScopeLLPInfo.llps.map((llp, index) => index === llpIndex ?
            {
              ...llp,
              [keyParam]: value,
              csn_out: keyParam == "cycles_remaining_out" ? value ? llp.max_limit - parseInt(value) : 0 : llp.csn_out,
              llp_reimbursement: keyParam == "cycles_remaining_out" ? parseFloat((value - llp.remaining_on_new) * ((engine.stub_life_list_price * llp.price) / (engine.stub_life_limit * llp.max_limit))).toFixed(2) : llp.llp_reimbursement,
              edited_list_price: keyParam == "cycles_remaining_out" ? parseFloat((llp.price / llp.max_limit) * value).toFixed(2) : llp.edited_list_price
            } : llp)
        }
      }))
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ tabIndex: newValue, error: {} })
  }
  updateCustomLLPCyclesFn = (engine_no, customizeLLP, engine, customCycleRemain) => {
    let check = []
    Object.keys(customCycleRemain).map(cust => {
      let csnArr = Object.keys(customCycleRemain).map(item => customCycleRemain[item].csn)
      let LLPStatus = false
      check = csnArr.map(csn => csn == (engine.csn - engine.csn_at_lsv))
      return check
    })
    this.updateLLpCycleLimitApi(this.props, engine, engine_no, customizeLLP, check)
  }
  onLLPCycleChange = (id, keyParam, value) => {
    const { workScopeLLPInfo } = this.state;
    let llp = workScopeLLPInfo.llps.find(item => item.id === id);
    switch (keyParam) {
      case 'csn':
        llp = {
          ...llp,
          cycles_remaining: llp.max_limit - value,
          [keyParam]: value
        }
        break;
      case 'max_limit':
        llp = {
          ...llp,
          cycles_remaining: value - llp.csn,
          [keyParam]: value
        }
        break;
      case 'cycles_remaining':
        llp = {
          ...llp,
          csn: llp.max_limit - value,
          [keyParam]: value
        }
        break;
      default:

    }
    this.setState(prevState => ({
      ...prevState,
      workScopeLLPInfo: {
        ...prevState.workScopeLLPInfo,
        llps: prevState.workScopeLLPInfo.llps.map((item) => item.id === id ? llp : item)
      }
    }))
  }
  updateMrShopVisit = (e, key, value, engineIndex, mrType, shopVisit, wkIndex, workScopeParam, keyParam = 'mr') => {
    this.setState(prevState => ({
      ...prevState,
      mrLogDetail: {
        ...prevState.mrLogDetail,
        engines: {
          ...prevState.mrLogDetail.engines,
          [engineIndex]: {
            ...prevState.mrLogDetail.engines[engineIndex],
            sv_update: true,
            [mrType]: {
              shop_visit: shopVisit,
              [keyParam]: parseFloat(value),
            },
            workscopes: {
              ...prevState.mrLogDetail.engines[engineIndex]['workscopes'],
              [wkIndex]: {
                ...prevState.mrLogDetail.engines[engineIndex]['workscopes'][wkIndex],
                [workScopeParam]: value
              }
            }
          }
        }
      }
    }))
  }
  shopVisitWarningUpdate = (engine) => {
    const { mrLogDetail } = this.state
    this.setState(prevState => ({
      ...prevState,
      warningModal: false,
      mrLogDetail: {
        ...prevState.mrLogDetail,
        engines: {
          ...prevState.mrLogDetail.engines,
          [engine]: {
            ...prevState.mrLogDetail.engines[engine],
            edited_sv_card: false
          }
        }
      }
    }), () => this.engineUsageCalApi(this.props, { ...mrLogDetail.engines[engine], edited_sv_card: false }, engine))
  }
  handleChangeWorkscope = (workScope, type, selected, label, wk, wkIndex) => {
    const { mrLogDetail } = this.state
    this.changeWorkScopeApi(this.props, { engine_no: label, shop_visit_no: Number(wk) + 1, log_id: this.props.match.params.id }, type, selected, mrLogDetail.engines[label].workscopes[wkIndex].name, mrLogDetail.engines[label].workscopes[wk])
  }
  setLifeWingData = (label) => {
    this.setState(prevState => ({
      ...prevState,
      mrLogDetail: {
        ...prevState.mrLogDetail,
        engines: {
          ...prevState.mrLogDetail.engines,
          [label]: {
            ...prevState.mrLogDetail.engines[label],
            edit_low: {
              ...prevState.mrLogDetail.edit_low,
              low_fsv: prevState.mrLogDetail.engines[label].workscopes[0].life_of_wings,
              low_ssv: prevState.mrLogDetail.engines[label].workscopes[1].life_of_wings,
              low_tsv: prevState.mrLogDetail.engines[label].workscopes[2].life_of_wings,
            }
          }
        }
      }
    }))
  }
  updateLifeOnwing = (engineIndex, wkIndex, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      mrLogDetail: {
        ...prevState.mrLogDetail,
        engines: {
          ...prevState.mrLogDetail.engines,
          [engineIndex]: {
            ...prevState.mrLogDetail.engines[engineIndex],
            workscopes: {
              ...prevState.mrLogDetail.engines[engineIndex]['workscopes'],
              [wkIndex]: {
                ...prevState.mrLogDetail.engines[engineIndex]['workscopes'][wkIndex],
                [keyParam]: value
              }
            }

          }
        }
      }
    }))
  }
  updateLowDataToEngine = (engineIndex, shopVisit, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      mrLogDetail: {
        ...prevState.mrLogDetail,
        engines: {
          ...prevState.mrLogDetail.engines,
          [engineIndex]: {
            ...prevState.mrLogDetail.engines[engineIndex],
            edit_low: {
              ...prevState.mrLogDetail.edit_low,
              shop_visit: shopVisit,
              [keyParam]: parseInt(value)
            }
          }
        }
      }
    }))
  }
  editReginFn = (data) => {
    this.setState({ editRegion: { data: data, flag: true } })
  }
  getEngineTypeApi = (engineId) => {
    this.setState({ pageLoader: true })
    globalGetService('console/engine-types/', { engine_family_engine_type: engineId, 'mr-calculator': true })
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          this.setState({ engineTypes: response.data.data.engineTypes })
        }
      });
  }
  editEngineTypeFn = (data) => {
    this.setState({ editEngineType: { data: data, flag: true } })
    this.getEngineTypeApi()
  }
  onEngineTypeChange = (engineIndex, wkIndex, key, value, mrType, shopVisit, wkInd, workScopeParam, keyParam = 'mr') => {

    if (engineIndex) {
      this.setState(prevState => ({
        ...prevState,
        mrLogDetail: {
          ...prevState.mrLogDetail,
          engines: {
            ...prevState.mrLogDetail.engines,
            [engineIndex]: {
              ...prevState.mrLogDetail.engines[engineIndex],
              // sv_update: true,
              // [key]: value,
              workscopes: {
                ...prevState.mrLogDetail.engines[engineIndex].workscopes,
                [wkIndex]: {
                  ...prevState.mrLogDetail.engines[engineIndex].workscopes[wkIndex],
                  [key]: value
                }
              }
            }
          }
        }
      }))
    }
    else {
      this.setState(prevState => ({
        ...prevState,
        engineTypePayload: {
          ...prevState.engineTypePayload,
          edit_engine_type: value
        }
      }))
    }
  }
  updateEngineTypeFn = (mrLogDetail, data, engineNo, wkindex, regionData, setModal) => {
    let payload = Object.assign({}, data)
    payload = {
      ...payload,
      edit_region_or_thrust: {
        shopvisit: parseFloat(wkindex) + 1,
        engine_type: data?.workscopes[wkindex]?.edited_engine_type ? data?.workscopes[wkindex]?.edited_engine_type : data?.workscopes[wkindex]?.engine_type ? data?.workscopes[wkindex]?.engine_type : mrLogDetail?.generalInfo?.engineType ? mrLogDetail?.generalInfo?.engineType : null,
        region_of_operation: regionData?.regionOfOperation ? regionData?.regionOfOperation : data?.workscopes[wkindex]?.edited_region_of_operation ? data?.workscopes[wkindex]?.edited_region_of_operation : data?.workscopes[wkindex]?.region_of_operation ? data?.workscopes[wkindex]?.region_of_operation : mrLogDetail?.generalInfo?.regionOfOperation ? mrLogDetail?.generalInfo?.regionOfOperation : null,
        location: regionData?.location ? regionData?.location : (data?.workscopes[wkindex]?.edited_location?.address) ? data?.workscopes[wkindex]?.edited_location : data?.workscopes[wkindex]?.location ? data?.workscopes[wkindex]?.location : mrLogDetail?.location ? mrLogDetail?.location : null,
        variable_condition: regionData?.variable_condition ? regionData?.variable_condition : data?.workscopes[wkindex]?.edited_variable_condition ? data?.workscopes[wkindex]?.edited_variable_condition : data?.workscopes[wkindex]?.variable_condition ? data?.workscopes[wkindex]?.variable_condition : mrLogDetail?.generalInfo?.variable_condition_id ? mrLogDetail?.generalInfo?.variable_condition_id : null,
      }
    }
    this.updateEngineTypeApi(payload, this.props, engineNo, setModal)
  }
  getResponseBack = () => {
    const { mrLogDetail, tabIndex } = this.state
    if (tabIndex === 0) {
      this.loiMrApi({ log_id: this.props.match.params.id }, { engine_no: Object.keys(mrLogDetail.engines).map((item, index) => tabIndex === index ? item : null) })
    } else {
      this.loiMrApi({ log_id: this.props.match.params.id }, { engine_no: Object.keys(mrLogDetail.engines).map((item, index) => tabIndex === index ? item : null) })
    }
  }
  render() {
    const { engineTypePayload, left, refNoModal, eolErrors, deleteEOLContent, assemblyIndex, tabIndex, airframeEdit, apuEdit, landingGearEdit, enginesEdit, type, mrLogDetail, workScopeInfo, workScopeLLPInfo, pageLoader, error, genericError, typeEngine, airEngineError, apuInfoPopup, lgInfoPopup, airframeInfoPopup, editedSvCardFlag, lockEdit, warningModal, workscopeError, refEdit, remarksEdit, removeList, eolPageLoader, editRegion, engineTypes, editEngineType, lgTabIndex } = this.state;
    let engineNSVFlag = [];
    if (Object.keys(mrLogDetail) && mrLogDetail.engines) {
      Object.keys(mrLogDetail.engines).map((label, index) => {
        if (!mrLogDetail.engines[label].workscopes[Object.keys(mrLogDetail.engines[label].workscopes)[0]].nsv) {
          engineNSVFlag.push(index + 1);
        }
      });
    }

    const flagCheck = `${[{ type: 'Airframe', flag: airframeEdit }, { type: ' APU', flag: apuEdit }, { type: ' Engine', flag: enginesEdit }, { type: ' Landing Gear', flag: landingGearEdit }].filter(item => item.flag == true).map(item => item.type).join()} informations are not updated`
    return (
      <div className="mr-calculator-section">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: '16px' }}>
              <Link color="inherit" to="/logs" >
                <Typography className="flex-centered" component="h1">
                  Logs
                </Typography>
              </Link>
              {getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('C') != -1 ?
                <Link color="inherit" to="/initiate" >
                  <Typography className="flex-centered" component="h1">
                    Generate Finance Report
                  </Typography>
                </Link> : null
              }
              <p className="flex-centered" color="primary">
                <span style={{ fontFamily: 'Conv_IBMPlexSans-Medium' }}>{this.props.match.params.id}</span>
                {Object.keys(mrLogDetail).length ?
                  <span className='reference-no'>
                    {mrLogDetail.generalInfo.reference_number ?
                      <span className='show-ref'>
                        [Reference No. {mrLogDetail.generalInfo.reference_number}]
                        {getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !mrLogDetail.generalInfo.is_locked ?
                          <EditIcon onClick={() => this.setState({ refNoModal: true })} style={{ fontSize: '15px', position: 'relative', top: '2px', left: '2px', cursor: 'pointer' }} color='primary' /> :
                          <VisibilityIcon onClick={() => this.setState({ refNoModal: true })} style={{ fontSize: '15px', position: 'relative', top: '2px', left: '2px', cursor: 'pointer' }} color='primary' />
                        }
                      </span> :
                      <span className='add-ref' onClick={() => this.setState({ refNoModal: true })}>{getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !mrLogDetail.generalInfo.is_locked ? 'Add Reference' : 'View Reference'}</span>
                    }
                  </span> : null
                }
              </p>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ul className='list-inline flex-centered' style={{ float: 'right', marginBottom: '8px' }}>
              <li className='list-inline-item' style={{ cursor: 'pointer', marginRight: '6px',marginTop:'10px' }}>
                <Comments />
              </li>
              <li className="list-inline-item" style={{ cursor: 'pointer', marginRight: '6px' }} >
                {mrLogDetail.generalInfo && mrLogDetail.generalInfo.is_locked ?
                  <Tooltip title="Click to resume editing." placement="top" arrow>
                    <img src={lockIcon} width="35" onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: false })} />
                  </Tooltip>
                  :
                  <Tooltip title="Click to lock editing." placement="top" arrow>
                    <img src={unlockIcon} width="35" onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: true })} />
                  </Tooltip>
                }
              </li>
              {assemblyIndex === 'loi' ? null : getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') != -1 ?
                <li className="list-inline-item">
                  {Object.keys(mrLogDetail).length && getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('C') != -1 ? <Button size="small" color="primary" variant="contained" disabled={engineNSVFlag.length || apuEdit || airframeEdit || landingGearEdit || enginesEdit} onClick={() => this.validateUsageApi(this.props)} style={isMobile ? { float: 'left', marginTop: '3px' } : { float: 'right' }}>Analyze MR Cashflow</Button> : null}
                  <br />
                  {engineNSVFlag.length ?
                    <span className="update-engine-label" style={{ fontSize: '9px', color: '#ff0000', clear: 'both', display: 'block' }}>Update engine <b>{engineNSVFlag.map((label, index) => engineNSVFlag.length - 1 != index ? '#' + label + ' & ' : '#' + label)}</b> usage  details to Review MR Data</span>
                    : apuEdit || enginesEdit || airframeEdit || landingGearEdit ?
                      <span style={{ fontSize: '10px', color: '#ff0000', clear: 'both', display: 'block', float: 'right' }}>{flagCheck}</span> : null}
                </li>
                : null
              }
            </ul>
          </Grid>
        </Grid>
        <Paper className='commverge-intermediate-card'>
          <Hidden only={['md', 'lg', 'xl']}>
            <Container style={{ position: 'relative', borderBottom: '1px solid #d7d7d7', background: 'rgb(232 234 234)', padding: '5px', marginBottom: '3px' }}>
              <Grid className="flex-centered ">
                <MenuOpenIcon
                  style={{ marginLeft: '5px', }}
                  fontSize='medium'
                  color="primary"
                  onClick={() => this.setState({ left: true })}
                />
              </Grid>
            </Container>
            <Drawer open={left} onClose={() => this.setState({ left: false })}>
                <Paper className="mobile-py-link" elevation={0} style={{ height: '100%', width: '200px', background: '#8073c1' }}>
                  <div className='side-bar-style'>
                    {mrLogDetail && mrLogDetail.airframe ?
                      <Link onClick={(assemblyType) => this.setState({ assemblyIndex: 'airframe',left:false })} className={`menu-option ${assemblyIndex === 'airframe' ? 'active' : ''}`}>
                        <h4>Airframe</h4>
                      </Link> : null
                    }
                    {mrLogDetail && mrLogDetail.engines ?
                      <Link onClick={(assemblyType) => this.setState({ assemblyIndex: 'engines',left:false })} className={`menu-option ${assemblyIndex === 'engines' ? 'active' : ''}`}>
                        <h4>Engines</h4>
                      </Link> : null
                    }
                    {mrLogDetail && mrLogDetail.apu ?
                      <Link onClick={(assemblyType) => this.setState({ assemblyIndex: 'apu',left:false })} className={`menu-option ${assemblyIndex === 'apu' ? 'active' : ''}`}>
                        <h4>APU</h4>
                      </Link> : null
                    }
                    {mrLogDetail && mrLogDetail.landingGear ?
                      <Link onClick={(assemblyType) => this.setState({ assemblyIndex: 'landing_gear',left:false })} className={`menu-option ${assemblyIndex === 'landing_gear' ? 'active' : ''}`}>
                        <h4>Landing Gears</h4>
                      </Link> : null
                    }
                    {mrLogDetail && Object.keys(mrLogDetail).length ?
                      <Link onClick={(assemblyType) => this.setState({ assemblyIndex: 'eoi',left:false })} className={`menu-option ${assemblyIndex === 'eoi' ? 'active' : ''}`}>
                        <h4>End of Lease</h4>
                      </Link> : null
                    }
                    {Object.keys(mrLogDetail).includes('engines') ?
                      <Link onClick={(assemblyType) => {
                        this.setState({ assemblyIndex: 'loi',left:false }); this.loiMrApi({ log_id: this.props.match.params.id }, { engine_no: tabIndex });
                        this.deRateVariableApi(this.props);
                      }} className={`menu-option ${assemblyIndex === 'loi' ? 'active' : ''}`}>
                        <h4>LOI MR Table</h4>
                      </Link> : null
                    }

                  </div>
                </Paper>
            </Drawer>
          </Hidden>
          <Hidden only={['xs', 'sm']}>
            <div className='commverge-assembly-menu'>
              {mrLogDetail && mrLogDetail.airframe ?
                <div onClick={(assemblyType) => this.setState({ assemblyIndex: 'airframe' })} className={`menu-option ${assemblyIndex === 'airframe' ? 'active' : ''}`}>
                  <h6>Airframe</h6>
                </div> : null
              }
              {mrLogDetail && mrLogDetail.engines ?
                <div onClick={(assemblyType) => this.setState({ assemblyIndex: 'engines' })} className={`menu-option ${assemblyIndex === 'engines' ? 'active' : ''}`}>
                  <h6>Engines</h6>
                </div> : null
              }
              {mrLogDetail && mrLogDetail.apu ?
                <div onClick={(assemblyType) => this.setState({ assemblyIndex: 'apu' })} className={`menu-option ${assemblyIndex === 'apu' ? 'active' : ''}`}>
                  <h6>APU</h6>
                </div> : null
              }
              {mrLogDetail && mrLogDetail.landingGear ?
                <div onClick={(assemblyType) => this.setState({ assemblyIndex: 'landing_gear' })} className={`menu-option ${assemblyIndex === 'landing_gear' ? 'active' : ''}`}>
                  <h6>Landing Gears</h6>
                </div> : null
              }
              {mrLogDetail && Object.keys(mrLogDetail).length ?
                <div onClick={(assemblyType) => this.setState({ assemblyIndex: 'eoi' })} className={`menu-option ${assemblyIndex === 'eoi' ? 'active' : ''}`}>
                  <h6>End of Lease</h6>
                </div> : null
              }
              {Object.keys(mrLogDetail).includes('engines') ?
                <div onClick={(assemblyType) => {
                  this.setState({ assemblyIndex: 'loi' }); this.loiMrApi({ log_id: this.props.match.params.id }, { engine_no: tabIndex });
                  this.deRateVariableApi(this.props);
                }} className={`menu-option ${assemblyIndex === 'loi' ? 'active' : ''}`}>
                  <h6>LOI MR Table</h6>
                </div> : null
              }

            </div>
          </Hidden>
          <div className='commverge-assembly-card'>
            <div className=''>
              {mrLogDetail.apu && assemblyIndex === 'apu' ?
                <APUCard
                  type={type}
                  mrLogDetail={mrLogDetail}
                  onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'apu')}
                  genericError={genericError}
                  updateGenericErrorField={this.updateGenericErrorField}
                  apuEdit={apuEdit}
                  toggleEditFn={() => this.setState({ apuEdit: true })}
                  apuUpdateUsageFn={() => this.apuUpdateUsageFn(this.props, mrLogDetail.apu)}
                  apuUpdateUsageApi={() => this.apuUpdateUsageApi(this.props, mrLogDetail.apu)}
                  cancelUsageUpdateFn={() => this.cancelUsageUpdateApi(this.props, 'apu')}
                  openInfoPopupFlag={() => this.setState({ apuInfoPopup: true })}
                  closeInfoPopup={() => this.setState({ apuInfoPopup: false })}
                  apuInfoPopup={apuInfoPopup}
                  isLocked={mrLogDetail.generalInfo.is_locked}
                /> : null
              }

              {mrLogDetail.landingGear && assemblyIndex === 'landing_gear' ?
                <>
                  <Grid alignItems='center' container spacing={1}>
                    <Grid item md={6}>
                      <Tabs
                        value={lgTabIndex}
                        onChange={(e, value) => this.setState({ lgTabIndex: value, error: {} })}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                      >
                        {Object.keys(mrLogDetail.landingGear).map((label, index) =>
                          <Tab label={`LG #${index + 1}`} />
                        )}
                      </Tabs>
                    </Grid>
                  </Grid>
                  {(mrLogDetail.landingGear).map((data, index) =>
                    (index) === lgTabIndex ?
                      <LGCard
                        index={index}
                        data={data}
                        type={type}
                        mrLogDetail={mrLogDetail}
                        onFieldChange={(e, keyParam, value) => this.onLgFieldChange(index, e, keyParam, value, 'landingGear')}
                        genericError={genericError[index]}
                        updateGenericErrorField={this.updateGenericErrorField}
                        landingGearEdit={landingGearEdit}
                        toggleEditFn={() => this.setState({ landingGearEdit: true })}
                        lgUpdateUsageFn={() => this.lgUpdateUsageFn(this.props, mrLogDetail.landingGear)}
                        lgUpdateUsageApi={() => this.lgUpdateUsageApi(this.props, mrLogDetail.landingGear)}
                        cancelUsageUpdateFn={() => this.cancelUsageUpdateApi(this.props, 'landingGear')}
                        openInfoPopupFlag={() => this.setState({ lgInfoPopup: true })}
                        closeInfoPopup={() => this.setState({ lgInfoPopup: false })}
                        lgInfoPopup={lgInfoPopup}
                        isLocked={mrLogDetail.generalInfo.is_locked}
                      />
                      : null
                  )}
                </>
                : null
              }
              {assemblyIndex === 'eoi' ?
                <EOICard
                  mrLogDetail={mrLogDetail}
                  onFocusEOL={this.onFocusEOL}
                  onFocusEolConidtion={this.onFocusEolConidtion}
                  eolErrors={eolErrors}
                  onDeleteEOLContent={this.onDeleteEOLContent}
                  pltConstants={this.props.pltConstants}
                  addEoiConditions={this.addEoiConditions}
                  onEoiFieldChange={this.onEoiFieldChange}
                  eol_scenarios={this.state.eol_scenarios}
                  addNewEolScenarios={this.addNewEolScenarios}
                  eol_conditionList={this.state.eol_conditionList}
                  props={this.props}
                  getEolScenariosApi={this.getEolScenariosApi}
                  eoiUpdateApi={this.addEditEoiFn}
                  onFieldChangeEolCndtn={this.onFieldChangeEolCndtn}
                />
                : null
              }
              {mrLogDetail.airframe && assemblyIndex === 'airframe' ?
                <AirframeCard
                  onChangeOtherCost={this.onChangeOtherCost}
                  onAddRemoveOtherCost={this.onAddRemoveOtherCost}
                  type={type}
                  mrLogDetail={mrLogDetail}
                  onFieldChange={(e, keyParam, value, eventType) => this.onFieldChange(e, keyParam, value, 'airframe', eventType)}
                  airEngineError={airEngineError}
                  updateAirEngineError={this.updateAirEngineError}
                  airframeEdit={airframeEdit}
                  toggleEditFn={() => this.setState({ airframeEdit: true })}
                  airframeUpdateUsageFn={() => this.airframeUpdateUsageApi(this.props, mrLogDetail.airframe, mrLogDetail)}
                  cancelUsageUpdateFn={() => this.cancelUsageUpdateApi(this.props, 'airframe')}
                  isLocked={mrLogDetail.generalInfo.is_locked}
                />
                : null
              }
              {assemblyIndex === 'loi' ?
                <>
                  <Grid alignItems='center' container spacing={1}>
                    <Grid item md={6}>
                      <Tabs
                        value={tabIndex}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                      >
                        {Object.keys(mrLogDetail.engines).map((label, index) => (
                          <Tab
                            label={`Engine #${index + 1}`}
                            onClick={() =>
                              this.loiMrApi(
                                { log_id: this.props.match.params.id },
                                { engine_no: label }
                              )
                            }

                          />
                        ))}
                      </Tabs>
                    </Grid>
                  </Grid>
                  {Object.keys(mrLogDetail.engines).map((label, index) =>
                    tabIndex === index ?
                      <LOICalModal
                        tabIndex={tabIndex}
                        getResponseBack={() => this.getResponseBack()}
                        loi={this.state.loi}
                        deRateList={this.state.deRateList}
                        buttonText={`LOI MR Table (Engine #${index + 1})`}
                        log_id={this.props.match.params.id}
                        engineBasicInfo={{ ratio: mrLogDetail.generalInfo.ratio, min_ratio: mrLogDetail.generalInfo.min_ratio, max_ratio: mrLogDetail.generalInfo.max_ratio, engineNo: label }}
                      /> : null
                  )}
                </>
                : null
              }

              {mrLogDetail.engines && assemblyIndex === 'engines' ?
                <div className="engine-assembly-card">
                  <Grid container spacing={1}>
                    <Grid xs={10} item md={editEngineType?.flag ? 8 : 6}>
                      <Table border={1} borderColor='#d7d7d7'>
                        <TableRow>
                          <TableCell width={'25%'}>
                            <p>Engine Type
                              {/* {
                                editEngineType?.flag ? <>
                                  <span onClick={() => {this.getMrLogDetailApi() }}>
                                    <Tooltip title="Cancel" placement="top" arrow>
                                      <CancelIcon style={{ color: '#f50057', cursor: 'pointer' }}className="sv-edit-icon" />
                                    </Tooltip>
                                  </span>
                                  <span onClick={() => this.updateEngineTypeFn() }>
                                    <Tooltip title="Save" placement="top" arrow>
                                      <DoneIcon style={{ color: '#3f51b5', marginLeft: '5px', cursor: 'pointer', fontSize:'18px' }} className="sv-edit-icon" />
                                    </Tooltip>
                                  </span></>
                                  : <Tooltip title="Edit" placement="top" arrow><EditIcon style={{ cursor: 'pointer' }} color="primary" fontSize="small" className="sv-edit-icon" onClick={() => this.editEngineTypeFn(mrLogDetail?.engines)} /></Tooltip>
                              } */}
                            </p>
                            {
                              editEngineType?.flag ?
                                <Autocomplete
                                  options={engineTypes}
                                  getOptionLabel={option => option.name}
                                  id="asset_type"
                                  // disabled={addEditAssembly.mode === 'edit' || isfetchingList ? true : false}
                                  value={mrLogDetail.generalInfo.engineType || null}
                                  onChange={(e, value) => { this.onEngineTypeChange(e, 'engineType', value, 'generalInfo') }}
                                  renderInput={params => <TextField required {...params} placeholder={pageLoader ? 'Fetching the List' : "Select Asset Type"} margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                                /> :
                                <h4>{mrLogDetail.generalInfo.engineType && mrLogDetail.generalInfo.engineType.name ? mrLogDetail.generalInfo.engineType.name : '--'}</h4>
                            }
                          </TableCell>
                          <TableCell width={'35%'}>
                            <p>Region Of Operation

                              {/* { getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1  ? */}
                              {/* <Tooltip title="Edit" placement="top" arrow><EditIcon style={{cursor:'pointer'}} color="primary" fontSize="small" className="sv-edit-icon" onClick={()=>this.editReginFn(mrLogDetail?.engines)} /></Tooltip>  */}
                              {/* : null}  */}
                            </p>
                            <h4>
                              {mrLogDetail.location && mrLogDetail.location.address ?
                                mrLogDetail.location.address.length > 20 ? <Tooltip title={mrLogDetail.location.address}><span>{`${mrLogDetail.location.address.substr(0, 20)}...`}</span></Tooltip> :
                                  mrLogDetail.location.address :
                                (mrLogDetail.generalInfo.engineType && mrLogDetail.generalInfo.regionOfOperation.name ? mrLogDetail.generalInfo.regionOfOperation.name : '--')
                              }
                            </h4>
                          </TableCell>
                          <TableCell width={'15%'}>
                            <p>Derate</p>
                            <h4>{mrLogDetail.generalInfo?.derate?.value ? mrLogDetail.generalInfo.derate.value : '--'}</h4>
                          </TableCell>
                          <TableCell width={'25%'}>
                            <p>Utilization Ratio</p>
                            <h4>{`${mrLogDetail.generalInfo.ratio}:1`}</h4>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </Grid>
                  </Grid>
                  <div>
                    <Grid alignItems='center' container spacing={1}>
                      <Grid item md={6}>
                        <Tabs
                          value={tabIndex}
                          onChange={this.handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                        >
                          {Object.keys(mrLogDetail.engines).map((label, index) =>
                            <Tab label={`Engine #${index + 1}`} />
                          )}
                        </Tabs>
                      </Grid>
                    </Grid>

                    {Object.keys(mrLogDetail.engines).map((label, index) =>
                      tabIndex === index ?
                        <div>
                          <div className='engine-shop-visit-wk'>
                            <div className='shop-visit-info'>
                              <EngineShopVisits
                                mrLogDetail={mrLogDetail}
                                updateEngineTypeFn={(data, wkindex, regionData, setModal) => this.updateEngineTypeFn(mrLogDetail, data, index, wkindex, regionData, setModal)}
                                onEngineTypeChange={this.onEngineTypeChange}
                                getEngineTypeApi={this.getEngineTypeApi}
                                engineTypes={engineTypes}
                                engine={mrLogDetail.engines[label]}
                                engineIndex={label}
                                engineWorkScopes={mrLogDetail.engines[label].workscopes}
                                changeWorkScopeFn={(wks, type, selected, wk, wkIndex) => this.handleChangeWorkscope(wks, type, selected, label, wk, wkIndex)}
                                carryOverNSVFn={(data, type, wk) => this.carryOverNSVApi(this.props, { engine_no: label, log_id: this.props.match.params.id, shop_visit_no: Number(wk) + 1, ...data }, mrLogDetail.engines[label].workscopes[wk].name, type)}
                                getResponseBack={() => this.getMrLogDetailApi(this.props, assemblyIndex)}
                                onFieldChange={this.updateMrShopVisit}
                                updateMrRate={(data, engineIndex, mr, key, shopVisit, wkName, type) => this.updateMrRateShopVisitApi(this.props, data, engineIndex, mr, key, shopVisit, wkName, type)}
                                cancelUsageUpdateFn={(param) => this.cancelUsageUpdateApi(this.props, param)}
                                updateLowApi={(mr, key, shopVisit) => this.engineUsageCalApi(this.props, mrLogDetail.engines[label], label, mr, key, shopVisit)}
                                updateLifeOnwing={this.updateLifeOnwing}
                                updateLowDataToEngine={(engineIndex, shopVisit, keyParam, value) => this.updateLowDataToEngine(engineIndex, shopVisit, keyParam, value)}
                              />
                            </div>
                          </div>
                          <EngineCard
                            engine={mrLogDetail.engines[label]}
                            type={typeEngine}
                            onFieldChange={this.onFieldChange}
                            engineIndex={label}
                            updateEngineUsageFn={() => { this.engineUsageCalFn(this.props, mrLogDetail.engines[label], label); this.setState({ warningModal: true }) }}
                            updateEngineUsageApi={() => this.engineUsageCalApi(this.props, mrLogDetail.engines[label], label)}
                            shopVisitWarningUpdate={() => this.shopVisitWarningUpdate(label)}
                            error={genericError}
                            updateErrorField={this.updateGenericErrorField}
                            customCycleRemainFn={() => this.customCycleRemainApi(this.props)}
                            mrLogDetail={mrLogDetail}
                            updateCustomLLPCyclesFn={this.updateCustomLLPCyclesFn}
                            updateEngineUsageFlag={() => this.setState({ enginesEdit: 'edit' })}
                            enginesEdit={enginesEdit}
                            toggleEditFn={() => this.setState({ enginesEdit: true })}
                            cancelUsageUpdateFn={() => { this.cancelUsageUpdateApi(this.props, 'engines'); this.setState({ genericError: {} }) }}
                            editedSvCardFlag={editedSvCardFlag}
                            closeEditedCard={() => this.setState({ editedSvCardFlag: false })}
                            viewLlpFn={() => this.setState({ enginesEdit: false })}
                            isLocked={mrLogDetail.generalInfo.is_locked}
                            warningModal={warningModal}
                          />
                        </div> : null
                    )}
                  </div>
                </div> : null
              }
            </div>
          </div>
        </Paper>

        <Paper style={{ padding: '15px', display: 'none' }}>
          <ul className='list-inline' style={{ float: 'right' }}>
            {refEdit ?
              <Fragment>
                <li className='list-inline-item'>
                  <Button onClick={() => { this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: {}, reference_number: mrLogDetail.generalInfo.reference_number, remarks: mrLogDetail.generalInfo.remarks }); this.setState({ refEdit: false }) }} color="primary" variant="contained" size="small">Save</Button>
                </li>
                <li className='list-inline-item'>
                  <Button onClick={() => { this.getMrLogDetailApi(this.props, assemblyIndex); this.setState({ refEdit: false }) }} color="secondary" variant="text" size="small">Cancel</Button>
                </li>
              </Fragment> :
              mrLogDetail && mrLogDetail.generalInfo && mrLogDetail.generalInfo.is_locked === false ?
                <li className='list-inline-item'>
                  <Tooltip title="Edit" placement="top" arrow><EditIcon onClick={() => this.setState({ refEdit: true })} color="primary" style={{ cursor: 'pointer', fontSize: '18px' }} /></Tooltip>
                </li> : null
            }
          </ul>
          <Grid container spacing={2}>
            {refEdit == true ?
              <Grid item xs={12}>
                <TextField
                  style={{ width: '150px', verticalAlign: 'baseline' }}
                  id="reference_number"
                  label='Reference Number'
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  value={mrLogDetail.generalInfo.reference_number ? mrLogDetail.generalInfo.reference_number : ''}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => this.onFieldChange(e, 'reference_number', e.target.value, 'generalInfo')}
                  variant="outlined"
                />
              </Grid>
              :
              <Grid item xs={12}>
                <p>Reference Number</p>
                <h6>{mrLogDetail.generalInfo && mrLogDetail.generalInfo.reference_number ? mrLogDetail.generalInfo.reference_number : '--'}</h6>
              </Grid>
            }
            {refEdit == true ?
              <Grid item xs={12}>
                <TextField
                  style={{ width: '80%', verticalAlign: 'baseline' }}
                  id="remarks"
                  label="Remarks"
                  fullWidth
                  multiline
                  margin="normal"
                  inputProps={{ maxLength: 255 }}
                  value={mrLogDetail.generalInfo.remarks ? mrLogDetail.generalInfo.remarks : ''}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => this.onFieldChange(e, 'remarks', e.target.value, 'generalInfo')}
                  variant="outlined"
                />
              </Grid> :
              <Grid item xs={12}>
                <p style={{ fontSize: '13px' }}>Remarks</p>
                <h4 style={{ fontSize: '14px' }}>{mrLogDetail.generalInfo && mrLogDetail.generalInfo.remarks ? mrLogDetail.generalInfo.remarks : '--'}</h4>
              </Grid>
            }
          </Grid>
        </Paper>
        {workScopeInfo.modal ?
          <WorkScopeModal
            workScopeInfo={workScopeInfo}
            toggleModalFn={() => this.setState({ workScopeInfo: { modal: false, data: {} } })}
            carryOverNSVFn={(data) => this.carryOverNSVApi(this.props, { engine_no: workScopeInfo.engine_no, log_id: this.props.match.params.id, shop_visit_no: workScopeInfo.data.run_id, ...data })}
          /> : null
        }
        {refNoModal ?
          <LogRefNo
            modal={refNoModal}
            mrLogDetail={mrLogDetail}
            toggleModalFn={() => this.setState({ refNoModal: false })}
            updateRefNo={(data) => this.updateLockApi(this.props, data)}
            isLocked={mrLogDetail.generalInfo.is_locked}
          /> : null
        }
        {pageLoader || eolPageLoader ? <PageLoader /> : null}
        {deleteEOLContent?.modal ?
          <DeletePopUp
            modal={deleteEOLContent.modal}
            toggleModalFn={() => this.setState({ deleteEOLContent: { modal: false } })}
            title={'Delete '}
            content={<h4>{`Are you sure you want to Delete this ?`}</h4>}
            deleteRecordFn={this.onDeleteEOLContentRow}
            confirmText={'Delete'}
          /> : null
        }
        {
          editRegion?.flag ?
            <EditRegionModal
              mrLogDetail={mrLogDetail}
              data={editRegion?.data}
              flag={editRegion?.flag}
              onClose={() => this.setState({ editRegion: { flag: false } })}
            />
            : null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
})
const mapDispatchToProps = (dispatch) => {
  return {
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({ constant_types: ['eol_condition', 'eol_mint_condition', 'eol_lease_type', 'eol_mint_condition'] })),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(IntermediateMrCal));

import moment from 'moment';
import { backendDateFormat } from '../../constants';

export const mrCalFilterOps = {
  log_id: {
    inputType: 'text',
    placeholder: 'Enter Log ID',
    title:'Log ID'
  },
  engine_type : {
    inputType: 'dropdown',
    placeholder: 'Select Engine Type',
    title: 'Engine Type',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  aircraft_type : {
    inputType: 'dropdown',
    placeholder: 'Select Aircraft Type',
    title: 'Aircraft Type',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  apu_type: {
    inputType: 'dropdown',
    placeholder: 'Select APU Type',
    title: 'APU Type',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  reference_number: {
    inputType: 'text',
    placeholder: 'Enter Reference Number',
    title:'Reference Number'
  },

};
export const mrLogsHd = [
  { id:'log_id', label: 'Log ID', sortOption: true },
  { id:'reference_number', label: 'Reference Number' },
  { id:'updated_at', label: 'Generated / Updated On', sortOption: true },
  { label: 'Created by', sortOption: false },
  { label: 'Country / Region of Operation', sortOption: false,style: {width: '200px'} },
  { label: 'Engine Utilization Ratio', sortOption: false },
  { label: 'Major Assemblies selected', sortOption: false },
  { label: "Lock Status", sortOption:false }
];
export const engineEventHd = [
  {label:'Shop Visits', sortOption:false},
  {label:'Shop Visit Date', sortOption:false},
  {label:'Escalated MR Rate as of Shop Visit Date', sortOption:false},
  {label:'Escalated Shop Visit Cost as of Shop Visit Date	', sortOption:false},
  {label:'Fund Value', sortOption:false},
  {label:'Balance', sortOption:false}
];
export const projectionEventHd = [
  {label:'Event', sortOption:false},
  {label:'Due Date', sortOption:false},
  {label:'Ground Time (Days)', sortOption:false},
  {label:'Escalated MR Rate as of Due Date', sortOption:false},
  {label:'Escalated Event Cost as of Date', sortOption:false},
  {label:'Fund Value', sortOption:false},
  {label:'Balance', sortOption:false, tooltip:true}
];
export const engineGenInfo = {
  engineType:null,
  utlizationRatio:null,
  regionOfOperation:null,
  region_country:'',
  noOfEngine:1,
  tsn:0,
  csn:0,
  variable_condition_id:[],
  utilization_hours:300
}
export const apuGenInfo = {
  apuType:null,
  tsn:'',
  hourlyUtlization:'300',
  autopopulate: false,
  dateOfLastOverhual:moment().format(backendDateFormat),
  as_of_date:moment().format(backendDateFormat)
};
export const lgGenInfo = {
  csn:'',
  cycleUtlization:'150',
  autopopulate: false,
  dateOfLastOverhual:moment().format(backendDateFormat),
  as_of_date:moment().format(backendDateFormat)
};
export const aircraftGenInfo = {
  aircraftType:null,
  dateOfManufacture:moment().format(backendDateFormat),
  maintenanceProgram:null,
  tsn:'0',
  csn:'0',
  hourlyUtlization:'300',
  cycleUtlization:'150',
  utlizationRatio:'2.0'
};
export const errorCode = {
  maintenanceProgram:{
    0:'',
    1:'Please select Maintenance Program'
  },
  maintenanceProgramObj:{
    required:true
  },
  dateOfManufacture:{
    0:'',
    1:'Please enter Date of Manufacture',
    5:'Please enter valid Date'
  },
  dateOfManufactureObj:{
    required:true,
    datePattern:true
  },
  dateOfLastOverhual:{
    0:'',
    1:'Please enter Date of Last Overhaul',
    5:'Please enter valid Date'
  },
  dateOfLastOverhualObj:{
    required:true,
    datePattern:true
  },
  aircraftType:{
    0:'',
    1:'Please select Aircraft Type'
  },
  aircraftTypeObj:{
    required:true
  },
  engineType:{
    0:'',
    1:'Please select Engine Type'
  },
  engineTypeObj:{
    required:true
  },
  regionOfOperation:{
    0:'',
    1:'Please select Region of Operation'
  },
  regionOfOperationObj:{
    required:true
  },
  utlizationRatio:{
    0:'',
    1:'Please select Utilization Ratio'
  },
  utlizationRatioObj:{
    required:true
  },
  apuType:{
    0:'',
    1:'Please select APU Type'
  },
  apuTypeObj:{
    required:true
  },
  lgType:{
    0:'',
    1:'Please select Landing Gear Type'
  },
  lgTypeObj:{
    required:true
  },
  hourlyUtlization:{
    0:'',
    1:'Please enter Flight Hours'
  },
  hourlyUtlizationObj:{
    required:true
  },
  cycleUtlization:{
    0:'',
    1:'Please enter Flight Cycles'
  },
  cycleUtlizationObj:{
    required:true
  },
  tsn:{
    0:'',
    1:'Please enter Time Since New'
  },
  tsnObj:{
    required:true
  },
  tsn_at_lsv:{
    0:'',
    1:'Please enter TSN at Last Shop Visit'
  },
  tsn_at_lsvObj:{
    required:true
  },
  csn:{
    0:'',
    1:'Please enter Cycles Since New'
  },
  csnObj:{
    required:true
  },
  csn_at_lsv:{
    0:'',
    1:'Please enter CSN at Last Shop Visit'
  },
  csn_at_lsvObj:{
    required:true
  },
  utilization_hours:{
    0:'',
    1:'Please enter Utilization Hours'
  },
  utilization_hoursObj:{
    required:true
  },
  country:{
    0:'',
    1:'Please select Country'
  },
  countryObj:{
    required:true
  },
  state:{
    0:'',
    1:'Please select State'
  },
  stateObj:{
    required:true
  },
  edited_list_price:{
    0:'',
    1:'Please enter the Price'
  },
  edited_list_priceObj:{
    required:true
  }
}
export const engineUsageErrorCode = {
  hour_util:{
    0:'',
    1:'Please enter Flight Hours'
  },
  hour_utilObj:{
    required:true
  },
  fund_date:{
    0:'',
    1:'Please enter Fund Date',
    5:'Please enter Valid Fund Date'
  },
  fund_dateObj:{
    required:true,
    datePattern:true
  },
  lsv_date:{
    0:'',
    1:'Please enter Last SV Date',
    5:'Please enter Valid Last SV Date'
  },
  lsv_dateObj:{
    required:true,
    datePattern:true
  },
  tsn_at_lsv:{
    0:'',
    1:'Please enter TSN at Last SV'
  },
  tsn_at_lsvObj:{
    required:true
  },
  csn_at_lsv:{
    0:'',
    1:'Please enter CSN at Last SV'
  },
  csn_at_lsvObj:{
    required:true
  },
  tsn:{
    0:'',
    1:'Please Enter TSN'
  },
  tsnObj:{
    required:true
  },
  csn:{
    0:'',
    1:'Please Enter CSN'
  },
  csnObj:{
    required:true
  }
}
export const genericErrorCode = {
  fundDate:{
    0:'',
    1:'Please enter Fund Balance as of Date',
    5:'Pleas enter Valid Date'
  },
  fundDateObj:{
    required:true,
    datePattern:true
  },
  actualMR:{
    0:'',
    1:'Please enter MR Rate'
  },
  actualMRObj:{
    required:true
  },
  lastShopvisitDate:{
    0:'',
    1:'Please enter Last SV Date',
    5:'Please enter valid Last SV Date'
  },
  lastShopvisitDateObj:{
    required:true,
    datePattern:true
  },
  tsn_at_lsv:{
    0:'',
    1:'Please enter TSN at Last SV'
  },
  tsn_at_lsvObj:{
    required:true
  },
  csn_at_lsv:{
    0:'',
    1:'Please enter CSN at Last SV'
  },
  csn_at_lsvObj:{
    required:true
  },
  tsn:{
    0:'',
    1:'Please Enter TSN'
  },
  tsnObj:{
    required:true
  },
  csn:{
    0:'',
    1:'Please Enter CSN'
  },
  csnObj:{
    required:true
  }
}
export const deRateErrorCode = {
  incRatio:{
    0:'',
    1:'Please enter Increment Ratio'
  },
  incRatioObj:{
    required:true
  }
}
export const financialPlotsErrorCode = {
  mr:{
    0:'',
    1:'Please enter MR Rate'
  },
  mrObj:{
    required:true
  },
  eventCost:{
    0:'',
    1:'Please enter Event Cost'
  },
  eventCostObj:{
    required:true
  },
  eventDate:{
    0:'',
    1:'Please enter Due Date',
    5:'Please enter Valid Date'
  },
  eventDateObj:{
    required:true,
    datePattern:true
  }
}
export const financialPlotsEngineErrorCode = {
  engine_mr:{
    0:'',
    1:'Please enter MR Rate'
  },
  engine_mrObj:{
    required:true
  },
  llp_mr:{
    0:'',
    1:'Please enter MR Rate'
  },
  llp_mrObj:{
    required:true
  },
  mr:{
    0:'',
    1:'Please enter MR Rate'
  },
  mrObj:{
    required:true
  },
  eventCost:{
    0:'',
    1:'Please enter Event Cost'
  },
  eventCostObj:{
    required:true
  },
  hourly_utilization:{
    0:'',
    1:'Please enter Monthly Utilization in Hours'
  },
  hourly_utilizationObj:{
    required:true
  },
  cycle_utilization:{
    0:'',
    1:'Please enter Monthly Utilization in Cycles'
  },
  cycle_utilizationObj:{
    required:true
  }
}
export const eoiErrorCode ={
  lease_start_date:{
    0:'',
    1:'Please enter Lease Start Date'
  },
  lease_start_dateObj:{
    required:true
  },
  lease_end_date:{
    0:'',
    1:'Please enter Lease End Date'
  },
  lease_end_dateObj:{
    required:true
  },
  lease_type:{
    0:'',
    1:'Please Select Lease Type'
  },
  lease_typeObj:{
    required:true
  },
  component_name:{
    0:'',
    1:'Please Select Component Name'
  },
  component_nameObj:{
    required:true
  },
  expected_mint_condition:{
    0:'',
    1:'Please Select Component Name'
  },
  expected_mint_conditionObj:{
    required:true
  },
  value:{
    0:'',
    1:'Please Select Value'
  },
  valueObj:{
    required:true
  },

}
